<script setup lang="ts">
import Header from './header.vue'
import { useHead, useBreadcrumbItems } from '#imports'
import { Link as StyleLink, Head } from '#components'
import { ALink } from '~/components/ui'
import IconHouse from '@material-symbols/svg-600/outlined/house.svg'
import Footer from './footer.vue'

const links = useBreadcrumbItems()

useHead({
  htmlAttrs: {
    class: 'legacy'
  }
})

</script>

<template>
  <div class="dashboard">
    <Head>
      <StyleLink
        href="/js/jquery/jquery-ui-010512/jquery-ui-1.8.16.custom.min.css"
        rel="stylesheet"
        type="text/css"
        title="ui-theme"
      />
      <StyleLink
        type="text/css"
        media="screen, projection"
        src="/css/freshwidget.css"
        rel="stylesheet"
      />
    </Head>

    <Header />

    <div class="container">
      <div>

        <section class="breadcrumbs">
          <template v-for="(item, index) in links" :key="item.to">
            <span v-if="index === links.length - 1">
              {{ item.label }}
            </span>
            <ALink
              :to="item.to"
              v-else
              data-testid="Legacy__homeIcon"
            >
              <template v-if="index === 0">
                <IconHouse />
              </template>
              <template v-else>
                {{ item.label }}
              </template>
            </ALink>
            <span
              class="divider"
              v-if="index !== links.length - 1"
            >/</span>
          </template>
        </section>
        <slot />
      </div>

    </div>
    <Footer />
  </div>
</template>

<style scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--text-color-secondary);
  gap: 0.5rem;
  margin-bottom: 2rem;
}
@media print {
  .breadcrumbs {
    display: none;
  }
}
</style>
